














































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { meetingsStore } from '@/store'
import { tdSocket } from '@/ws/tdSocket'

@Component({
  components: {
    MeetingCardFull: () => import('@/components/Meetings/Cards/Full.vue'),
    IconNoMeetings: () => import('@/components/UI/icons/IconNoMeetings.vue'),
  },
})
export default class MeetingsRightBarList extends Vue {
  @Prop({
    required: true,
    type: Date,
  }) readonly startDate!: Date

  @Prop({
    required: true,
    type: Date,
  }) readonly endDate!: Date

  private limit = 30
  private offset = 0

  private listenerId: string | undefined

  async created () {
    await this.loadMeetings()

    // Subscribe to cell update
    // If the instance of this cell is displayed in the right column,
    // you need to reload the list, most likely the sorting will change
    this.listenerId = tdSocket.addListener('server.meetingcell.updated',
      p => this.updateCellInstance(p.meetingId, p.startAtOld),
    )
  }

  beforeDestroy () {
    this.listenerId && tdSocket.removeListener(this.listenerId)
  }

  get loading () {
    return meetingsStore.state.isMeetingsLoading
  }

  get interval (): { startDate: string; endDate: string } {
    const interval = {
      startDate: '',
      endDate: '',
    }
    interval.startDate = this.startDate.toISOString()
    interval.endDate = this.endDate.toISOString()
    return interval
  }

  get meetingsInstance () {
    return meetingsStore.state.meetingsInstances
  }

  async loadMeetings (details?: { to: number }): Promise<void> {
    if (details) {
      let { to } = details
      to += 1
      if (to < this.limit || to < this.offset) return
      this.offset += to === (this.offset + this.limit) ? this.limit : 0
    }
    await meetingsStore.actions.loadMeetingsInstances({
      ...this.interval,
      limit: this.limit,
      offset: this.offset,
    })
  }

  get selectedMeetingUUID () {
    return meetingsStore.state.selectedMeetingUUID
  }

  private readonly thumbStyle: Partial<CSSStyleDeclaration> = {
    right: '2px',
    borderRadius: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    width: '4px',
    opacity: '1',
  }

  get isHaveMeetings (): boolean {
    return this.meetingsInstance.length > 0 || this.loading
  }

  @Watch('startDate', { immediate: true })
  private async watchStartDate (): Promise<void> {
    this.offset = 0
    await this.loadMeetings()
  }

  private async updateCellInstance (meetingId: string, oldStartAt: string) {
    let hasUpdatedMeeting = false
    this.meetingsInstance.forEach(m => {
      if (m.id !== meetingId && m.startAt !== oldStartAt) return
      hasUpdatedMeeting = true
    })
    hasUpdatedMeeting && await this.loadMeetings()
  }
}
