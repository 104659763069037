import { TDSocket } from 'td-socket'
import { getWebsocketUrl } from '@/ws/Utils'
import i18n from '@/i18n'
import { uiSettingsStore } from '@/store'
import InactiveDetector from '@/electron/InactiveDetector'

const active = InactiveDetector.isActive

const url = getWebsocketUrl(
  uiSettingsStore.getters.language || i18n.locale || 'ru',
  false,
  !active,
  undefined,
  Intl.DateTimeFormat().resolvedOptions().timeZone,
)

const tdSocket = new TDSocket(url)

export {
  tdSocket,
}
